import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './index.css';
import Nav from './views/Nav/Nav';
import Loading from './components/Loading/Loading';
import Noticies from './views/Noticies/Noticies.routes';
import Form from './views/Form/Form.routes';
import NotFound from './views/NotFound/NotFound';
import Navigate from './components/Navigate';
import Puntuacio from './views/Puntuacio/Puntuacio';
const Dashboard = React.lazy(() => import('./Dashboard'));
const Legal = React.lazy(() => import('./views/Legal/Legal.routes'));
const Home = React.lazy(() => import('./views/Home/Home'));
const Pd9 = React.lazy(() => import('./views/Pd9/Pd9'));
const Contacte = React.lazy(() => import('./views/Contacte/Contacte'));
const CollaRouter = React.lazy(() => import('./views/Colla/Colla.routes'));
const Patrocinadors = React.lazy(() => import('./views/Patrocinadors/Patrocinadors'));
const Calendari = React.lazy(() => import('./views/Calendari/Calendari'));
const Historic = React.lazy(() => import('./views/Historic/Historic'));
const ImatgeMarca = React.lazy(() => import('./views/ImatgeMarca/ImatgeMarca'));
const GrallesTabals = React.lazy(() => import('./views/GrallesTabals/GrallesTabals'));
const Footer = React.lazy(() => import('./views/Footer/Footer'));

class LazyComponent extends React.Component<{ component: any }> {
  render(): React.ReactNode {
    return (
      <React.Suspense fallback={<Loading />}>
        {this.props.component}
      </React.Suspense>
    )
  }
}

export default class App extends React.Component {
  render(): React.ReactNode {
    if (window.location.pathname.includes('/dash')) return <Dashboard />;
    const is404 = window.location.pathname.includes('/404');
    return (
      <Router>
        <GoToTop />
        <Nav hide={is404} />
        <main className='body'>
          <Routes>
            <Route path='/' element={<LazyComponent component={<Home />} />} />
            <Route path='/404' element={<LazyComponent component={<NotFound />} />} />
            <Route path='*' element={<Navigate to='/404' />} />
            <Route path='/calendari' element={<LazyComponent component={<Calendari />} />} />
            <Route path='/gralles' element={<LazyComponent component={<GrallesTabals />} />} />
            <Route path='/imatge' element={<LazyComponent component={<ImatgeMarca />} />} />
            <Route path='/colla/*' element={<LazyComponent component={<CollaRouter />} />} />
            <Route path='/plaçade9' element={<LazyComponent component={<Pd9 />} />} />
            <Route path='/actuacions' element={<LazyComponent component={<Historic />} />} />
            <Route path='/patrocinadors' element={<LazyComponent component={<Patrocinadors />} />} />
            <Route path='/contacte' element={<LazyComponent component={<Contacte />} />} />
            <Route path='/legal/*' element={<LazyComponent component={<Legal />} />} />
            <Route path='/noticies/*' element={<LazyComponent component={<Noticies />} />} />
            <Route path='/n/*' element={<LazyComponent component={<Noticies />} />} />
            <Route path='/form/*' element={<LazyComponent component={<Form />} />} />
            <Route path='/puntuacio' element={<LazyComponent component={<Puntuacio />} />} />
          </Routes>
        </main>
        <LazyComponent component={<Footer hide={is404} />} />
      </Router>
    );
  }
};

function GoToTop() {
  const location = useLocation()
  useEffect(() => {
    document.getElementsByClassName("App")[0].scrollIntoView();
  }, [location])
  return <></>
}