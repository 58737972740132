import React from "react";
import "./Editor.scss";
import { Tronc } from "../Tronketis";
import events, { Castell } from "../Events";

enum TCastell {
  PILAR = 1,
  TORRE = 2,
  CASTELL = 3,
}

enum TRengla {
  RENGLA = 1,
  AGULLA = 2,
}

enum Rengla {
  RENGLA = 'rengla',
  AGULLA = 'agulla',
}

export default class Editor extends React.Component<{
  date?: Date;
}, {
  proves?: {
    [key: string]: Castell;
  };
}> {
  constructor(props: {}) {
    const query = new URLSearchParams(window.location.search);
    const day = Object.keys(events).find((e) => events[e].date.toString() === query.get('date'))
    const dia = events[parseInt(day)];
    super(props);
    this.state = {
      proves: dia.proves,
    };
  }

  render() {
    const query = new URLSearchParams(window.location.search);
    const day = Object.keys(events).find((e) => events[e].date.toString() === query.get('date'))
    const dia = events[parseInt(day)];
    console.log(events[Object.keys(events).find((e) => events[e].date.toString() === query.get('date'))]);
    return (
      <React.Fragment>
        <section className="days">
          <div key={day} className="dia">
            <h2>
              {new Date(dia.date).toLocaleDateString(
                'ca-ES',
                {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )}
            </h2>
            <section className="proves">
              {
                Object.keys(dia.proves).map((key) => {
                  const prova = dia.proves[parseInt(key)];
                  return (
                    <div key={key} className="prova" data-prova={key}>
                      <h3>{prova.name}</h3>
                      <button className="checkbox" onClick={
                        (e) => {
                          const prova = e.currentTarget.parentElement as HTMLDivElement;
                          const pisos = prova.querySelectorAll('section.rengles div.rengla ul.pisos li.pis input[type="text"]');
                          pisos.forEach((pis) => {
                            (pis as HTMLInputElement).disabled = !(pis as HTMLInputElement).disabled;
                          });
                        }
                      }>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                          <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                      <button className="delete" onClick={
                        (e) => {
                          this.setState({
                            proves: Object.keys(dia.proves).reduce((acc, k) => {
                              if (parseInt(k) !== parseInt(key)) {
                                acc[parseInt(k)] = dia.proves[parseInt(k)];
                              }
                              return acc;
                            }, {}),
                          });
                        }
                      }>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                          <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <section className="rengles">
                        {
                          Object.keys(prova.rengles).map((key) => {
                            const rengla = prova.rengles[parseInt(key)];
                            const type = Rengla[TRengla[rengla.type as TRengla]];
                            return (
                              <div key={key} className={`rengla ${type}`}>
                                <ul className="pisos">
                                  {
                                    Object.keys(rengla.pisos).map((key, i) => {
                                      const attr = {
                                        'n': 0,
                                        'a': 0,
                                        'f': 1,
                                        'fa': 1,
                                        'fm': 2,
                                        'sm': 1,
                                        'sf': 0,
                                      };
                                      const member = typeof rengla.pisos[key] === 'string' ? Tronc.Member.getMember(rengla.pisos[key]) : rengla.pisos[key] as Tronc.Member;
                                      const alturaSumada = Object.keys(rengla.pisos).slice(0, i + 1).reduce((acc, k) => {
                                        return acc + (Tronc.Member.getMember(rengla.pisos[k].nom).height % 100);
                                      }, 0);
                                      const castellWOattr = prova.name.slice(4);
                                      const tapat = Number(key) <= (castellWOattr.length > 0 ? attr[castellWOattr] : 0);
                                      const pisos = {
                                        0: 'B',
                                        1: 'S',
                                        2: 'T',
                                        3: '4',
                                        4: '5',
                                        5: '6',
                                        6: '7',
                                      }
                                      return (
                                        <li key={key} className="pis" data-pis={pisos[key]} datatype={`${tapat ? 't' : ''}`} data-name={member.nom} onClick={
                                          (e) => {
                                            const input = e.currentTarget.querySelector('input[type="text"]') as HTMLInputElement;
                                            if (!input.disabled) return;
                                            const alreadySelected = document.querySelectorAll('div.prova ul.pisos li.pis.selected');
                                            alreadySelected.forEach((selected) => {
                                              selected.classList.remove('selected');
                                            });
                                            const proves = document.querySelectorAll(`div.prova ul.pisos li.pis[data-name="${member.nom}"]`);
                                            proves.forEach((prova) => {
                                              prova.classList.toggle('selected');
                                            });
                                          }
                                        }>
                                          <input type="text" name={member.nom} value={member.nom} disabled />
                                          <span className="height">{alturaSumada}</span>
                                        </li>
                                      );
                                    })
                                  }
                                </ul>
                                <h4>{rengla.name}</h4>
                              </div>
                            );
                          })
                        }
                      </section>
                    </div>
                  );
                })
              }
              <div className="afegirProva" onClick={
                () => {
                  const input = document.getElementsByName('afegirProva')[0] as HTMLInputElement;
                  input.click();
                }
              }>
                <input
                  type="text"
                  name="afegirProva"
                  disabled={false}
                  placeholder={'Enganxa un tronc copiat de l\'Excel amb les altures incloses'}
                  autoComplete="off"
                  onInput={
                    (e: React.FormEvent) => {
                      e.preventDefault();
                      const input = (e.target as HTMLInputElement).value.replace(/\d+\t\t/g, (match) => '@#').replace(/\t|[0-9]+$/g, '').replace(/[0-9]+/g, '@');
                      const pisos = input.split(/@\x20/).filter((f) => !new RegExp(/^[^a-zA-Z]*$/).test(f)).map((l) => l.split('@'));
                      if (pisos.length === 0) return;
                      const h = Object.keys(pisos).length;
                      const l = Object.keys(pisos[0]).length;
                      const x = new Array(l).fill(0).map((_, i) => new Array(h).fill(0).map((_, j) => pisos[j][i]).reverse());
                      const d = document.querySelector('div.afegirProva') as HTMLDivElement;
                      const section = document.createElement('section');
                      section.className = 'rengles';
                      const t = {
                        1: {
                          0: 'Pilar',
                        },
                        2: {
                          0: 'Acotxador',
                          1: 'Enxaneta',
                        },
                        3: {
                          0: 'Rengla',
                          1: 'Plena',
                          2: 'Buida',
                        },
                        '3a': {
                          0: 'Rengla',
                          1: 'Plena',
                          2: 'Buida',
                          3: 'Agulla',
                        },
                        4: {
                          0: 'Dos',
                          1: 'Acotxador',
                          2: 'Dos',
                          3: 'Enxaneta',
                        },
                        '4a': {
                          0: 'Dos',
                          1: 'Acotxador',
                          2: 'Dos',
                          3: 'Enxaneta',
                          4: 'Agulla',
                        },
                        5: {
                          0: 'Acotxador',
                          1: '',
                          2: 'Rengla',
                          3: 'Plena',
                          4: 'Buida',
                        },
                        7: {
                          0: 'Dos',
                          1: 'Acotxador',
                          2: 'Dos Tort',
                          3: 'Rengla',
                          4: 'Dos',
                          5: 'Acotxador',
                          6: 'Dos + Enxa.',
                        }
                      };
                      const agulla = x[x.length - 1].every((p) => p.startsWith('#'));
                      // ------------------------------------------------------
                      x.forEach((rengla) => {
                        const isAgulla = rengla.every((p) => p.startsWith('#'));
                        const div = document.createElement('div');
                        div.className = `rengla${isAgulla ? ' agulla' : ''}`;
                        const ul = document.createElement('ul');
                        ul.className = 'pisos';
                        rengla.map((pis) => pis.replace('#', '')).forEach((pis) => {
                          const member = Tronc.Member.getMember(pis);
                          const li = document.createElement('li');
                          li.className = 'pis';
                          const input = document.createElement('input');
                          input.type = 'text';
                          input.name = member.nom;
                          input.value = member.nom;
                          input.disabled = false;
                          li.appendChild(input);
                          ul.appendChild(li);
                        });
                        div.appendChild(ul);
                        section.appendChild(div);
                        const h4 = document.createElement('h4');
                        console.log(t[`${l - (agulla ? 1 : 0)}${(agulla ? 'a' : '')}`])
                        h4.textContent = t[`${l - (agulla ? 1 : 0)}${(agulla ? 'a' : '')}`][Object.keys(section.querySelectorAll('div.rengla')).length - 1];
                        div.appendChild(h4);
                      });
                      d.appendChild(section);
                      // ------------------------------------------------------
                      const options = document.createElement('div');
                      options.className = 'options';
                      const button = document.createElement('button');
                      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                      svg.setAttribute('viewBox', '0 0 24 24');
                      svg.setAttribute('width', '24');
                      svg.setAttribute('height', '24');
                      svg.setAttribute('color', '#000000');
                      svg.setAttribute('fill', 'none');
                      const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
                      path.setAttribute('d', 'M8 22V19C8 17.1144 8 16.1716 8.58579 15.5858C9.17157 15 10.1144 15 12 15C13.8856 15 14.8284 15 15.4142 15.5858C16 16.1716 16 17.1144 16 19V22');
                      path.setAttribute('stroke', 'currentColor');
                      path.setAttribute('strokeWidth', '1.5');
                      path.setAttribute('strokeLinejoin', 'round');
                      svg.appendChild(path);
                      const path2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
                      path2.setAttribute('d', 'M10 7H14');
                      path2.setAttribute('stroke', 'currentColor');
                      path2.setAttribute('strokeWidth', '1.5');
                      path2.setAttribute('strokeLinecap', 'round');
                      path2.setAttribute('strokeLinejoin', 'round');
                      svg.appendChild(path2);
                      const path3 = document.createElementNS("http://www.w3.org/2000/svg", "path");
                      path3.setAttribute('d', 'M3 11.8584C3 7.28199 3 4.99376 4.38674 3.54394C4.43797 3.49038 4.49038 3.43797 4.54394 3.38674C5.99376 2 8.28199 2 12.8584 2C13.943 2 14.4655 2.00376 14.9628 2.18936C15.4417 2.3681 15.8429 2.70239 16.6452 3.37099L18.8411 5.20092C19.9027 6.08561 20.4335 6.52795 20.7168 7.13266C21 7.73737 21 8.42833 21 9.81025V13C21 16.7497 21 18.6246 20.0451 19.9389C19.7367 20.3634 19.3634 20.7367 18.9389 21.0451C17.6246 22 15.7497 22 12 22C8.25027 22 6.3754 22 5.06107 21.0451C4.6366 20.7367 4.26331 20.3634 3.95491 19.9389C3 18.6246 3 16.7497 3 13V11.8584Z');
                      path3.setAttribute('stroke', 'currentColor');
                      path3.setAttribute('strokeWidth', '1.5');
                      svg.appendChild(path3);
                      button.appendChild(svg);
                      const rh = x[0].length + 3;
                      button.onclick = () => {
                        const prova = {
                          name: `${x.length - (agulla ? 1 : 0)}de${rh}${agulla ? 'a' : ''}`,
                          type: TCastell.CASTELL,
                          rengles: {},
                        };
                        const rengles = section.querySelectorAll('section.rengles div.rengla');
                        rengles.forEach((rengla, i) => {
                          const pisos = rengla.querySelectorAll('ul.pisos li.pis input[type="text"]');
                          const name = rengla.querySelector('h4').textContent;
                          const isAgulla = rengla.classList.contains('agulla');
                          prova.rengles[i + 1] = {
                            name: name,
                            type: isAgulla ? TRengla.AGULLA : TRengla.RENGLA,
                            pisos: {},
                          };
                          pisos.forEach((p, j) => {
                            prova.rengles[i + 1].pisos[j] = Tronc.Member.getMember((p as HTMLInputElement).value);
                          });
                        });
                        const proves = dia.proves;
                        proves[Object.keys(proves).length + 1] = prova;
                        this.setState({
                          proves: proves,
                        });
                        d.removeChild(section);
                        d.removeChild(options);
                        (e.target as HTMLInputElement).disabled = false;
                        (e.target as HTMLInputElement).value = '';
                      };
                      options.appendChild(button);
                      d.appendChild(options);
                      // ------------------------------------------------------
                      (e.target as HTMLInputElement).disabled = true;
                    }
                  } />
              </div>
            </section>
          </div>
        </section>
        <aside className="afegir">
          <button>
            AFEGEIX UN ESDEVENIMENT
          </button>
        </aside>
      </React.Fragment>
    );
  }
}