import React from "react";
import "./Comunicat.scss";
import { toPng } from "html-to-image";

export default class Comunicat extends React.Component {
  render() {
    return (
      <div id="comunicat">
        <section className="settings">
          <button className="download" onClick={
            () => {
              const sheets = document.querySelectorAll('.sheet');
              for (let i = 0; i < sheets.length; i++) {
                const sheet = sheets[i] as HTMLElement;
                toPng(sheet, { cacheBust: true, backgroundColor: 'white' })
                  .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = `comunicat-${i + 1}.png`;
                    link.href = dataUrl;
                    link.click();
                  })
              }
            }
          }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M4 7C4.58984 7.60684 6.15973 10 7 10C7.84027 10 9.41016 7.60684 10 7M7 9L7 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 13L4 14.5442C4 17.7892 4 19.4117 4.88607 20.5107C5.06508 20.7327 5.26731 20.9349 5.48933 21.1139C6.58831 22 8.21082 22 11.4558 22C12.1614 22 12.5141 22 12.8372 21.886C12.9044 21.8623 12.9702 21.835 13.0345 21.8043C13.3436 21.6564 13.593 21.407 14.0919 20.9081L18.8284 16.1716C19.4065 15.5935 19.6955 15.3045 19.8478 14.9369C20 14.5694 20 14.1606 20 13.3431V10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2M13 21.5V21C13 18.1716 13 16.7574 13.8787 15.8787C14.7574 15 16.1716 15 19 15H19.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <div className="fontSize">
            <button className="increase">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <path d="M12 4V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 12H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
            <span>100%</span>
            <button className="decrease">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <path d="M20 12L4 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </section>
        <ol className="sheets">
          <li className="sheet">
            <img src="/svg/logo3D.svg" alt="" />
            <input type="text" name="" className="titol" placeholder="Titol del comunicat" />
            <textarea name="" spellCheck='false' placeholder={`Davant dels greus incidents ocorreguts el passat diumenge durant la Diada de Santa Úrsula, la Colla Vella dels Xiquets de Valls vol expressar la seva condemna i rebuig per les accions violentes perpetrades per un membre de la Colla Joves Xiquets de Valls contra alguns dels nostres components més joves.

Concretament, denunciem un intent d'agressió a un menor de la nostra colla, així com una agressió física que va afectar un segon menor i una castellera de la Colla Vella. Aquestes accions són absolutament intolerables i representen una violació dels valors i principis de respecte que han de regir les relacions entre colles castelleres i el bon desenvolupament de les diades.

Per aquest motiu, des de la Colla Vella emprendrem les accions que es considerin oportunes per tal de garantir la protecció i seguretat dels nostres membres i assegurar que actes com aquest no quedin impunes.

Ens reafirmem en el nostre compromís amb un món casteller respectuós, segur i lliure de violència, i seguirem vetllant per preservar els valors d'aquesta tradició tan arrelada a la nostra cultura.

Ens posem també a disposició per trobar punts d'acord i entesa que ens segueixin unint en projectes comuns amb els Xiquets de Valls, en favor d'una convivència harmònica i de l'essència del fet casteller.

En aquest sentit, informem que la nostra Colla va sol·licitar una reunió urgent de la Comissió Xiquets de Valls per ahir dilluns, que finalment no es va poder cel·lebrar, quedant a l'espera que es pugui dur a terme en breu.`} />
          </li>
          <li className="afegirPagina">
            <button onClick={
              () => {
                const elementToClone = document.querySelector('.sheet') as HTMLElement;
                const newElement = elementToClone.cloneNode(true) as HTMLElement;
                newElement.querySelector('input').value = '';
                newElement.querySelector('textarea').value = '';
                elementToClone.parentNode.insertBefore(newElement, elementToClone.nextSibling);
              }
            }>Afegir Pàgina</button>
          </li>
        </ol>
      </div>
    );
  }
}