import React from "react";
import "./Stats.scss";

export default class Stats extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="stats">
        <div className="header">
          <div className="content">
            <h1>Estadístiques</h1>
          </div>
        </div>
        <section className="stats">
          <div className="title">
            <h2>Estadístiques</h2>
          </div>
          <ul className="stats">
            <li>
              <h3>Estadístiques</h3>
              <ul className="stats">
                <span className="name">Estadístiques</span>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}