import React from "react";
import "./Troncs.scss";
import { Tronc } from "./Tronketis";
import { Link, Route, Routes } from "react-router-dom";

import events from "./Events";

import Editor from "./Editor/Editor";
import Stats from "./Stats/Stats";

enum TCastell {
  PILAR = 1,
  TORRE = 2,
  CASTELL = 3,
}

enum TRengla {
  RENGLA = 1,
  AGULLA = 2,
}

interface IRengla {
  name: string;
  type: TRengla;
  pisos: {
    [key: number]: string | Tronc.Member;
  }
}

interface Castell {
  name: string;
  type: TCastell;
  rengles: {
    [key: number]: IRengla;
  };
}

export default class Troncs extends React.Component<{}, {
  proves?: {
    [key: number]: Castell;
  };
  days: {
    [key: number]: {
      date: Date;
      proves: {
        [key: number]: Castell
      };
    };
  };
}, {
  }> {
  constructor(props: {}) {
    const proves: {
      [key: number]: Castell
    } = {
      1: {
        name: '3de7a',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marcos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Vito,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Xavi,
              3: Tronc.Member.Martina,
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Jorge,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Zayra,
            },
          },
          4: {
            name: 'Agulla',
            type: TRengla.AGULLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Rocio,
              3: 'Julia',
            },
          },
        },
      },
      2: {
        name: '4de7a',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Dos',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marcos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Acotxador',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Jorge,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Gisela,
              3: Tronc.Member.Abril,
            },
          },
          3: {
            name: 'Dos',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Vito,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Xavi,
              3: Tronc.Member.NuriaB,
            },
          },
          4: {
            name: 'Enxaneta',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Albert,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Martina,
            },
          },
          5: {
            name: 'Agulla',
            type: TRengla.AGULLA,
            pisos: {
              0: Tronc.Member.MeriG,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Rocio,
              3: 'Julia',
            },
          },
        },
      },
      3: {
        name: '2de7',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Acotxador',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
            },
          },
          2: {
            name: 'Enxaneta',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Silvia,
            },
          },
        },
      },
      4: {
        name: '3de8',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Gisela,
              4: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
              4: Tronc.Member.Martina,
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Rucen,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Xavi,
              4: Tronc.Member.NuriaB,
            },
          },
        }
      },
      5: {
        name: '3de9f',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Gisela,
              4: Tronc.Member.Silvia,
              5: 'X',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
              4: Tronc.Member.Martina,
              5: 'Y',
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Rucen,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Xavi,
              4: Tronc.Member.NuriaB,
              5: 'Z',
            },
          },
        }
      },
      6: {
        name: 'Pde7f',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Didac,
              3: Tronc.Member.Javi,
              4: Tronc.Member.Abril,
              5: 'Julia',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Marius,
            },
          },
        }
      },
      7: {
        name: 'Pde8fm',
        type: TCastell.CASTELL,
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Didac,
              4: Tronc.Member.Javi,
              5: Tronc.Member.Irene,
              6: 'Julia',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Xavi,
            },
          },
        }
      }
    };
    super(props);
    this.state = {
      days: {
        1: {
          date: new Date('2024-10-24'),
          proves: {
            1: {
              name: '5de5n',
              type: TCastell.CASTELL,
              rengles: {
                1: {
                  name: 'Acotxador',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Gisela,
                    2: Tronc.Member.Irene,
                  },
                },
                2: {
                  name: '',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Marcos,
                    2: Tronc.Member.Zayra,
                  },
                },
                3: {
                  name: 'Rengla',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Avalos,
                    2: Tronc.Member.Silvia,
                  },
                },
                4: {
                  name: 'Plena',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Asier,
                    2: Tronc.Member.NuriaB,
                  },
                },
                5: {
                  name: 'Buida',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Marius,
                    2: Tronc.Member.Martina,
                  },
                },
              }
            },
            2: {
              name: 'Pde4',
              type: TCastell.CASTELL,
              rengles: {
                1: {
                  name: 'Pilar',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Ruben,
                    1: Tronc.Member.Xavi,
                    2: Tronc.Member.Martina,
                  },
                }
              }
            },
            3: {
              name: '7de5n',
              type: TCastell.CASTELL,
              rengles: {
                1: {
                  name: 'Dos',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Marius,
                    2: Tronc.Member.Zayra,
                  },
                },
                2: {
                  name: 'Acotxador',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Didac,
                    2: Tronc.Member.Martina,
                  },
                },
                3: {
                  name: 'Dos (tort)',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Samu,
                    2: Tronc.Member.Mutge,
                  },
                },
                4: {
                  name: 'Rengla',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Avalos,
                    2: Tronc.Member.Silvia,
                  },
                },
                5: {
                  name: 'Dos',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Asier,
                    2: Tronc.Member.Irene,
                  },
                },
                6: {
                  name: 'Acotxador',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Marcos,
                    2: Tronc.Member.Rocio,
                  },
                },
                7: {
                  name: 'Dos + Enxa.',
                  type: TRengla.RENGLA,
                  pisos: {
                    1: Tronc.Member.Albert,
                    2: Tronc.Member.NuriaB,
                  },
                },
              }
            },
            4: {
              name: '7de6',
              type: TCastell.CASTELL,
              rengles: {
                1: {
                  name: 'Dos',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.VictorC,
                    1: Tronc.Member.Marius,
                    2: Tronc.Member.Zayra,
                  },
                },
                2: {
                  name: 'Acotxador',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Vito,
                    1: Tronc.Member.Didac,
                    2: Tronc.Member.Martina,
                  },
                },
                3: {
                  name: 'Dos (tort)',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Ruben,
                    1: Tronc.Member.Samu,
                    2: Tronc.Member.Mutge,
                  },
                },
                4: {
                  name: 'Rengla',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Rucen,
                    1: Tronc.Member.Avalos,
                    2: Tronc.Member.Silvia,
                  },
                },
                5: {
                  name: 'Dos',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.MeriG,
                    1: Tronc.Member.Asier,
                    2: Tronc.Member.Irene,
                  },
                },
                6: {
                  name: 'Acotxador',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Maroto,
                    1: Tronc.Member.Marcos,
                    2: Tronc.Member.Rocio,
                  },
                },
                7: {
                  name: 'Dos + Enxa.',
                  type: TRengla.RENGLA,
                  pisos: {
                    0: Tronc.Member.Jorge,
                    1: Tronc.Member.Albert,
                    2: Tronc.Member.NuriaB,
                  },
                },
              }
            }
          },
        },
        2: {
          date: new Date('2021-09-11'),
          proves: proves,
        },
        3: {
          date: new Date('2021-09-11'),
          proves: proves,
        },
        4: {
          date: new Date('2021-09-11'),
          proves: proves,
        },
      },
    };
  }

  render() {
    return (
      <div id="troncs">
        <Routes>
          <Route path="/editor" element={
            <Editor />
          } />
          <Route path="/" element={
            <React.Fragment>
              <div>HOLA</div>
              <Link to={'editor?date=2024-10-24'}>Editor</Link>
              <ol>
                {
                  Object.entries(events).map(([key, day]) => {
                    console.log(day);
                    return (
                      <li key={key} className="day">
                        <Link to={`editor?date=${day.date}`}>
                          <h2>{day.name} / {day.date}</h2>
                        </Link>
                      </li>
                    );
                  })
                }
              </ol>
            </React.Fragment>
          } />
          <Route path="/stats" element={
            <Stats />
          } />
        </Routes>
      </div>
    );
  }
}