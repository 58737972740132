
export namespace Tronc {
  export class Member {
    id: number;
    nom: string;
    height: number;

    constructor(id: number, nom: string, height: number) {
      this.id = id;
      this.nom = nom;
      this.height = height;
    }

    public static getMember(s: string): Member {
      return Object.values(Member).find((m: Member) => m.nom === s) ?? { id: 0, nom: '', height: 0 };
    }

    // Baixos
    static readonly AlbaM = new Member(1, 'Alba M.', 141);
    static readonly Guillem = new Member(2, 'Guillem', 145);
    static readonly Joanma = new Member(3, 'Joanma', 138);
    static readonly Joel = new Member(4, 'Joel', 141);
    static readonly Jorge = new Member(5, 'Jorge', 143);
    static readonly MeriG = new Member(6, 'Meri G.', 138);
    static readonly Montse = new Member(7, 'Montse', 128);
    static readonly MontseZKS = new Member(8, 'Montse ZKS', 138);
    static readonly Ruben = new Member(9, 'Rubén', 137);
    static readonly Rucen = new Member(10, 'Rucen', 145);
    static readonly Vargas = new Member(11, 'Vargas', 144);
    static readonly VictorC = new Member(12, 'Víctor', 144);
    static readonly Vito = new Member(13, 'Vito', 139);
    static readonly Maroto = new Member(14, 'Maroto', 146);

    // Segons
    static readonly Albert = new Member(15, 'Albert', 149);
    static readonly Asier = new Member(16, 'Asier', 148);
    static readonly Avalos = new Member(17, 'Ávalos', 146);
    static readonly Ciscu = new Member(18, 'Ciscu', 146);
    static readonly Dami = new Member(19, 'Damià', 149);
    static readonly Didac = new Member(20, 'Dídac', 154);
    static readonly Farre = new Member(21, 'Farré', 141);
    static readonly Guifre = new Member(22, 'Guifré', 144);
    static readonly Junca = new Member(23, 'Juncà', 146);
    static readonly Lucia = new Member(24, 'Lucía', 142);
    static readonly Marcos = new Member(25, 'Marcos', 142);
    static readonly Marius = new Member(26, 'Marius', 148);
    static readonly Samu = new Member(27, 'Samu', 150);
    static readonly Tonix = new Member(28, 'ToniX', 147);

    // Terços
    static readonly Domenek = new Member(29, 'Domenek', 138);
    static readonly Gisela = new Member(30, 'Gisela', 139);
    static readonly Javi = new Member(31, 'Javi', 146);
    static readonly Jeyson = new Member(32, 'Jeyson', 140);
    static readonly Joanet = new Member(33, 'Joanet', 144);
    static readonly Laura = new Member(34, 'Laura', 138);
    static readonly Mutri = new Member(35, 'Mutri', 140);
    static readonly Toni = new Member(36, 'Toni', 143);
    static readonly Xavi = new Member(37, 'Xavi', 147);
    static readonly Xavito = new Member(38, 'Xavito', 142);

    // Quarts
    static readonly Abril = new Member(39, 'Abril', 128);
    static readonly Alba = new Member(40, 'Alba', 134);
    static readonly Amira = new Member(41, 'Amira', 129);
    static readonly Aneta = new Member(42, 'Aneta', 129);
    static readonly Arantza = new Member(43, 'Arantza', 134);
    static readonly Blanca = new Member(44, 'Blanca', 136);
    static readonly Edith = new Member(45, 'Edith', 129);
    static readonly Gemma = new Member(46, 'Gemma', 128);
    static readonly Irene = new Member(47, 'Irene', 136);
    static readonly Judith = new Member(48, 'Judith', 135);
    static readonly Martina = new Member(49, 'Martina', 132);
    static readonly Merce = new Member(50, 'Mercè', 132);
    static readonly Mutge = new Member(51, 'Mutgé', 137);
    static readonly NuriaB = new Member(52, 'Nuria B.', 130);
    static readonly Rocio = new Member(53, 'Rocío', 136);
    static readonly Roger = new Member(54, 'Roger', 138);
    static readonly Silvia = new Member(55, 'Sílvia', 131);
    static readonly Thais = new Member(56, 'Thaís', 133);
    static readonly Zayra = new Member(57, 'Zayra', 136);
  }
}