import React from "react";
import "./CartellV2.scss";
import * as htmlToImage from "html-to-image";

export default class CartellV2 extends React.Component<{}, {
  cartell: {
    titol: string;
    background: string;
    esdeveniments: {
      titol: string;
      data: string;
      hora: string;
      lloc: string;
      colles?: string[];
    }[];
  } | null;
  type: number;
}> {
  constructor(props: any) {
    super(props);
    this.state = {
      cartell: null,
      type: 1
    };
  }

  render(): React.ReactNode {
    return (
      <section className="cartell" datatype={`$${this.state.type.toString()}`}>
        <div className="options">
          <select name="SelectFormat" className="format" onInput={
            (e) => {
              this.setState({
                type: parseInt((e.target as HTMLSelectElement).value)
              });
            }
          }>
            <option value="1">Cuadrat</option>
            <option value="2">4 / 3</option>
            <option value="3">9 / 16</option>
            <option value="4">DinA</option>
          </select>
          <button className="download" onClick={
            () => {
              htmlToImage.toPng(document.querySelector('.preview.assaig') as HTMLElement, {
                quality: 1,
                pixelRatio: 3,
                style: {
                  borderRadius: '0',
                  border: 'none',
                  padding: '0',
                }
              }).then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'cartell.png';
                link.href = dataUrl;
                link.click();
              })
            }
          }>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
              <path d="M12 15L12 5M12 15C11.2998 15 9.99153 13.0057 9.5 12.5M12 15C12.7002 15 14.0085 13.0057 14.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5 19H19.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <input type="file" name="" id="" onChange={
            (e) => {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.setState({
                  cartell: {
                    ...this.state.cartell,
                    background: e.target?.result as string
                  }
                });
              }
              reader.readAsDataURL((e.target as HTMLInputElement).files?.[0] as Blob);
            }
          } />
          <input type="text" name="" placeholder="Títol" onInput={
            (e) => {
              this.setState({
                cartell: {
                  ...this.state.cartell,
                  titol: (e.target as HTMLInputElement).value
                }
              });
            }
          } />
          <ul className="esdeveniments">
            {
              this.state.cartell?.esdeveniments?.map((esdeveniment, i) => {
                return (
                  <li key={i}>
                    <input type="text" name="" placeholder="Títol" onInput={
                      (event) => {
                        this.setState({
                          cartell: {
                            ...this.state.cartell,
                            esdeveniments: this.state.cartell?.esdeveniments.map((e, j) => {
                              if (i === j) {
                                return {
                                  ...e,
                                  titol: (event.target as HTMLInputElement).value
                                };
                              }
                              return e;
                            })
                          }
                        });
                      }
                    } />
                    <input type="text" name="" placeholder="Data" onInput={
                      (event) => {
                        this.setState({
                          cartell: {
                            ...this.state.cartell,
                            esdeveniments: this.state.cartell?.esdeveniments.map((e, j) => {
                              if (i === j) {
                                return {
                                  ...e,
                                  data: (event.target as HTMLInputElement).value
                                };
                              }
                              return e;
                            })
                          }
                        });
                      }
                    } />
                    <input type="text" name="" placeholder="Hora" onInput={
                      (event) => {
                        this.setState({
                          cartell: {
                            ...this.state.cartell,
                            esdeveniments: this.state.cartell?.esdeveniments.map((e, j) => {
                              if (i === j) {
                                return {
                                  ...e,
                                  hora: (event.target as HTMLInputElement).value
                                };
                              }
                              return e;
                            })
                          }
                        });
                      }
                    } />
                    <input type="text" name="" placeholder="Lloc" onInput={
                      (event) => {
                        this.setState({
                          cartell: {
                            ...this.state.cartell,
                            esdeveniments: this.state.cartell?.esdeveniments.map((e, j) => {
                              if (i === j) {
                                return {
                                  ...e,
                                  lloc: (event.target as HTMLInputElement).value
                                };
                              }
                              return e;
                            })
                          }
                        });
                      }
                    } />
                    <input type="text" name="" placeholder="Colles" onInput={
                      (event) => {
                        this.setState({
                          cartell: {
                            ...this.state.cartell,
                            esdeveniments: this.state.cartell?.esdeveniments.map((e, j) => {
                              if (i === j) {
                                return {
                                  ...e,
                                  colles: (event.target as HTMLInputElement).value.split(',')
                                };
                              }
                              return e;
                            })
                          }
                        });
                      }
                    } />
                  </li>
                );
              })
            }
            <button onClick={
              () => {
                this.setState({
                  cartell: {
                    ...this.state.cartell,
                    esdeveniments: [
                      ...this.state.cartell?.esdeveniments || [],
                      {
                        titol: 'general',
                        data: '02/11',
                        hora: '20h',
                        lloc: 'c.c. l\'era',
                        colles: []
                      }
                    ]
                  }
                });
              }
            }>+</button>
          </ul>
        </div>
        <div className="preview default" datatype={`$${this.state.type.toString()}`}>
          <div className="decorations">
            <span className="circle white"></span>
            <span className="circle black"></span>
            <span className="circle green"></span>
            <span className="box"></span>
            <span className="text">CASTELLERS DE MOLLET</span>
          </div>
          <div className="title">
            {
              this.state.cartell?.titol ? <h2 className="titol">{this.state.cartell.titol}</h2> : <h2 className="titol">Escriu un titol</h2>
            }
          </div>
          {
            this.state.cartell?.background ? <img src={this.state.cartell.background} alt="Cartell" className="background" /> : <div className="empty">Selecciona una imatge de fons</div>
          }
        </div>
        <div className="preview assaig" datatype={`$${this.state.type.toString()}`}>
          <div className="decorations">
            <span className="text">ASSAIG</span>
          </div>
          <div className="title">
            {
              this.state.cartell?.titol ? <h2 className="titol">{this.state.cartell.titol}</h2> : <h2 className="titol">Escriu un titol</h2>
            }
          </div>
          {
            this.state.cartell?.background ? <img src={this.state.cartell.background} alt="Cartell" className="background" /> : <div className="empty">Selecciona una imatge de fons</div>
          }
          <ul className="esdeveniments">
            {
              this.state.cartell?.esdeveniments?.map((esdeveniment, i) => {
                return (
                  <li key={i}>
                    <h3>{esdeveniment.titol}</h3>
                    <p>{esdeveniment.data} - {esdeveniment.hora}</p>
                    <p>{esdeveniment.lloc}</p>
                    {
                      esdeveniment.colles?.map((colla, j) => {
                        return (
                          <p key={j}>{colla}</p>
                        );
                      })
                    }
                  </li>
                );
              })
            }
          </ul>
        </div>
      </section>
    );
  }
}