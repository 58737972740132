import React from 'react';

const castells = [
  'Pde5',
  'Pde6',
  'Pde7f',
  'Pde8fm',
  'Pde9fpm',
  '3de7',
  '3de8',
  '3de9f',
  '3de10fm',
  '4de10fm',
]

enum Base {
  $P = 5,
  $2 = 4,
  $3 = 3,
  $4 = 2,
  $5 = 3,
  $7 = 4,
  $9 = 5,
}

enum Modificador {
  A = 1.2,
  F = 1.4,
  FA = 1.6,
  FM = 1.8,
  SF = 2.0,
  SM = 2.2,
  FMP = 2.4,
}

export default class Puntuacio extends React.Component {
  render() {
    return (
      <div>
        <h1>Puntuació</h1>
        <p>La puntuació dels castells es calcula de la següent manera:</p>
        <ul>
          <li>El valor de cada castell és el nombre de pisos + 1, arrodonit a l'enter més proper.</li>
          <li>El valor de cada castell es multiplica pel modificador corresponent.</li>
          <li>La puntuació final és la suma de tots els valors.</li>
        </ul>
        {
          castells.map((castell) => {
            const regex = /^(P|\d+)de(\d+)([a-zA-Z]*)$/;
            const match = castell.match(regex);
            const X = Base[`$${match[1]}` as keyof typeof Base];
            const Y = match[2];
            const castellers = (!isNaN(parseInt(match[1])) ? parseInt(match[1]) : 1) * (parseInt(Y) - 3);
            const modificador = Modificador[match[3].toUpperCase() as keyof typeof Modificador] || 1.0;
            const valor = castellers * modificador;

            return (
              <div key={castell}>
                <h2>{castell}</h2>
                <span className="puntuacio">{valor}</span>
              </div>
            );
          })
        }
      </div>
    );
  }
}