import React from "react";
import "./Quotes.scss";
import { Quotes as DATA } from "../../../Form/Quotes2024";

export default class Quotes extends React.Component<{}, {
  search: string;
}> {
  render() {
    return (
      <div className="quotes">
        <h1>Quotes</h1>
        <p>En aquest apartat pots consultar, modificar, afegir o esborrar l'informació referent a la quota d'un membre de la colla.</p>
        <div className="data">
          <input type="text" onInput={
            (event) => {
              console.log((event.target as HTMLInputElement).value);
              this.setState({
                search: (event.target as HTMLInputElement).value
              });
            }
          } placeholder="Cerca..." value={this.state?.search} />
          <span className="totals" data-label="total">
            {Object.keys(DATA).length}
          </span>
          <span className="pagats" data-label="pagat">
            {Object.keys(DATA).filter(key => DATA[key]).length}
          </span>
          <span className="pendents" data-label="pendent">
            {Object.keys(DATA).filter(key => !DATA[key]).length}
          </span>
        </div>
        <section className="options">

        </section>
        <ul className="llista">
          {
            Object.keys(DATA)
              .sort((a, b) => Number(DATA[a]) - Number(DATA[b]))
              .sort((a, b) => {
                const letterA = Number(a) % 23;
                const letterB = Number(b) % 23;
                const DNI = 'TRWAGMYFPDXBNJZSQVHLCKE';
                const DNIA = `${a}${DNI.charAt(letterA)}`;
                const DNIB = `${b}${DNI.charAt(letterB)}`;
                return Number(DNIB.includes(this.state?.search)) - Number(DNIA.includes(this.state?.search));
              })
              .map((key, index) => {
              const letter = Number(key) % 23;
              const DNI = 'TRWAGMYFPDXBNJZSQVHLCKE';
              return (
                <li key={index} className={`quota ${DATA[key] ? 'pagat' : 'pendent'} ${`${key}${DNI.charAt(letter)}`.includes(this.state?.search) ? 'searched' : ''}`}>
                  {
                    DATA[key] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                        <path d="M11 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2 9H22" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
                        <path d="M14 18C14 18 15 18 16 20C16 20 19.1765 15 22 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                        <path d="M12.5 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C21.8957 6.57684 21.9897 8.11799 21.999 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2 9H22" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
                        <path d="M22 14L16 20M22 20L16 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                      </svg>
                    )
                  }
                  <span>{key}{DNI.charAt(letter)}</span>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}