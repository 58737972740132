import React from "react";
import './Form.scss';
import { Quotes } from "./Quotes2024";

export default class Form extends React.Component<{}, {
  message: string;
  validDNI: boolean | null;
}> {
  constructor(props: any) {
    super(props);
    this.state = {
      message: '',
      validDNI: null
    };
  }

  isControlLetterValid(dni: string): boolean {
    const controlLetter = dni.charAt(dni.length - 1).toUpperCase();
    const dniNumber = dni.substring(0, dni.length - 1);
    const controlLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const controlLetterCalculated = controlLetters.charAt(Number(dniNumber) % 23);
    return controlLetter === controlLetterCalculated;
  }

  testDNI(): boolean {
    const dni = (document.querySelector('input[name=dni]') as HTMLInputElement)?.value.toString();
    if (dni.length !== 9) return null;
    return new RegExp(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/ig).test(dni) && this.isControlLetterValid(dni);
  }

  getMemberData(): any {
    const dni = (document.querySelector('input[name=dni]') as HTMLInputElement)?.value.toString();
    const splitDni = dni.slice(0, -1);
    console.log(Quotes[splitDni]);
    if (Quotes[splitDni] !== undefined) {
      this.setState({ message: Quotes[splitDni] ? 'Hola! Tens la quota pagada.  Gràcies!' : 'Aixx! No has pagat la quota encara? Pots fer-ho en efectiu, amb tarjeta i també per transferència. Gràcies!' });
    } else {
      this.setState({ message: 'No s’ha trobat cap soci amb aquest DNI. Posa\'t en contacte amb el Corretger o la Mònica.Gràcies!' });
    }
  }

  getDataFromForm(): any {
    const input = document.querySelector('input[name=dni]') as HTMLInputElement;
    if (input) {
      if (this.testDNI()) {
        this.getMemberData();
      } else {
        this.setState({ message: 'DNI Invalid' });
      }
    }
  }

  render(): React.ReactNode {
    return (
      <div id="form">
        <h1>Consulta de Quotes Pagades</h1>
        <p>Posa el teu DNI per confirmar-ho.</p>
        <div className="form">
          <div className="dni">
            <label htmlFor="dni">Document Nacional d'Identitat (DNI)</label>
            <input type="text" id="dni" name="dni" maxLength={9} required onInput={
              (e) => {
                if ((e.target as HTMLInputElement).value.length !== 9) return this.setState({ validDNI: null, message: '' });
                this.setState({ validDNI: this.testDNI() })
              }
            } />
            {
              this.state.validDNI !== null && !this.state.validDNI && <span className="error">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                  <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M11.992 8H12.001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                DNI Invalid
              </span>
            }
          </div>
          <button onClick={
            () => this.getDataFromForm()
          }>
            Comprovar Quota
          </button>
        </div>
        <div className="message">
          {
            this.state.message &&
            <p>
              {this.state.message}
            </p>
          }
        </div>
      </div>
    );
  }
}