import React from "react";
import { Link } from "react-router-dom";
import "./Nav.scss";
import instagram from "../svg/instagram.svg";
import facebook from "../svg/facebook.svg";
import youtube from "../svg/youtube.svg";
import tiktok from "../svg/tiktok.svg";
import x from "../svg/x.svg";
import twitch from "../svg/twitch.svg";

export default class Nav extends React.Component<{
	hide?: boolean;
}, {  }> {
	constructor(props) {
		super(props);
		this.closeMenu = this.closeMenu.bind(this);
	}

	componentDidMount() {
		if(this.props.hide) return;
		document.addEventListener("keydown", this.closeMenu, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.closeMenu, false);
	}

	closeMenu(event) {
		if (event.key !== undefined && event.key !== "Escape") return;
		document.querySelector("#menu").classList.remove("open");
	}

	render(): React.ReactNode {
		if(this.props.hide) return null;
		return (
			<header id='header'>
				<Link to='/' className="home">
					<img src="../svg/logo3D.svg" alt="Logo" height='50px' width='50px' />
					<p>
						<span>CASTELLERS</span>
						<span>DE</span>
						<span>MOLLET</span>
					</p>
				</Link>
				<nav>
					<ul>
						<li>
							La Colla
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M12 4V20M20 12H4" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<ol className="sections">
								<li>
									<Link to='/colla'>Qui Som</Link>
								</li>
								<li>
									<Link to='/colla/local'>Local</Link>
								</li>
								<li>
									<Link to='/colla/actuacions'>Actuacions Fixes</Link>
								</li>
								<li>
									<Link to='/colla/activitats'>Activitats</Link>
								</li>
								<li>
									<Link to='/colla/organitzacio'>Organització</Link>
								</li>
							</ol>
						</li>
						<li>
							<Link to='/calendari'>Calendari</Link>
						</li>
						<li>
							<Link to='/gralles'>Gralles i Tabals</Link>
						</li>
						<li>
							<Link to='/actuacions'>Actuacions</Link>
						</li>
						<li>
							<Link to='/contacte'>Contacte</Link>
						</li>
					</ul>
				</nav>
				<ul className="xxss">
					<li className="ig"><a href="https://www.instagram.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={instagram} alt="Instagram" /></a></li>
					<li className="fb"><a href="https://www.facebook.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={facebook} alt="Facebook" /></a></li>
					<li className="yt"><a href="https://www.youtube.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={youtube} alt="Youtube" /></a></li>
					<li className="tt"><a href="https://www.tiktok.com/@castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={tiktok} alt="TikTok" /></a></li>
					<li className="x"><a href="https://www.x.com/CastellerMollet" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={x} alt="X" /></a></li>
					<li className="tw"><a href="https://www.twitch.tv/castellersdemollet" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={twitch} alt="Twitch" /></a></li>
				</ul>
				<div className="openMenu" onClick={
					() => {
						document.querySelector("#menu").classList.add("open");
					}}>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path d="M7 5L17 5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M4 12L20 12" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M7 19L17 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</div>
				<aside id="menu">
					<nav>
						<div className="closeMenu" onClick={this.closeMenu}>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M19 5L5 19M5 5L19 19" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</div>
						<ul>
							<li>
								<span onClick={
									(e) => {
										(e.target as HTMLElement).classList.toggle("open");
									}
								}>
									La Colla
									<svg xmlns="http://www.w3.org/2000/svg" height="1rem" viewBox="0 0 448 512" style={{ fontSize: '1.6rem' }}>
										<path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
									</svg>
								</span>
								<ol className="sections">
									<li>
										<Link to='/colla' onClick={this.closeMenu}>Qui Som</Link>
									</li>
									<li>
										<Link to='/colla/local' onClick={this.closeMenu}>Local</Link>
									</li>
									<li>
										<Link to='/colla/actuacions' onClick={this.closeMenu}>Actuacions Fixes</Link>
									</li>
									<li>
										<Link to='/colla/activitats' onClick={this.closeMenu}>Activitats</Link>
									</li>
									<li>
										<Link to='/colla/organitzacio' onClick={this.closeMenu}>Organització</Link>
									</li>
								</ol>
							</li>
							<li>
								<Link to='/calendari' onClick={this.closeMenu}>Calendari</Link>
							</li>
							<li>
								<Link to='/gralles' onClick={this.closeMenu}>Gralles i Tabals</Link>
							</li>
							<li>
								<Link to='/actuacions' onClick={this.closeMenu}>Actuacions</Link>
							</li>
							<li>
								<Link to='/contacte' onClick={this.closeMenu}>Contacte</Link>
							</li>
						</ul>
						<ul className="xxss">
							<li className="ig"><a href="https://www.instagram.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={instagram} alt="Instagram" /></a></li>
							<li className="fb"><a href="https://www.facebook.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={facebook} alt="Facebook" /></a></li>
							<li className="yt"><a href="https://www.youtube.com/castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={youtube} alt="Youtube" /></a></li>
							<li className="tt"><a href="https://www.tiktok.com/@castellersdemollet/" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={tiktok} alt="TikTok" /></a></li>
							<li className="x"><a href="https://www.x.com/CastellerMollet" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={x} alt="X" /></a></li>
							<li className="tw"><a href="https://www.twitch.tv/castellersdemollet" target="_blank" rel="noopener noreferrer"><img width='20px' height='20px' src={twitch} alt="Twitch" /></a></li>
						</ul>
					</nav>
				</aside>
			</header>
		);
	}
}