export namespace Permission {
  export enum Binary {
    ANY = 0,
    ADMIN = 1,
    TECNICA = 2,
    JUNTA = 4,
    COMUNICACIO = 8,
    ACCES = 16,
  }

  export enum String {
    ADMIN = "administrador",
    TECNICA = "tècnica",
    JUNTA = "junta",
    COMUNICACIO = "comunicació",
    ACCES = "accés",
  }

  export class Utils {
    public static hasPermission(permissions: number, permission: Binary): boolean {
      if (permission === Binary.ANY) return true;
      return !!(permissions & permission);
    }

    public static obtenerPermisos(numero: number): Permission.Binary[] {
      return Object.keys(Permission.Binary).reduce((permisosActivados: Permission.Binary[], permiso: string) => {
        const valorPermiso = Permission.Binary[permiso];
        if (typeof valorPermiso === 'number' && numero & valorPermiso) {
          permisosActivados.push(valorPermiso);
        }
        return permisosActivados;
      }, []);
    }
  }
}