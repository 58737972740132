import { Tronc } from "./Tronketis";

enum TRengla {
  RENGLA = 1,
  AGULLA = 2,
}

export class Rengla {
  constructor(
    public name: string,
    public type: TRengla,
    public pisos: {
      [key: number]: string | Tronc.Member;
    }
  ) {
    return this;
  }
}

export class Castell {
  constructor(
    public name: string,
    public rengles: {
      [key: number]: Rengla;
    },
  ) {
    return this;
  }
}

export class Day {
  constructor(
    public name: string,
    public date: string,
    public proves: {
      [key: string]: Castell;
    }
  ) { }
}

const events: {
  [key: string]: Day;
} = {
  1: new Day('Dilluns', '2024-10-24', {
    '1': new Castell('Pilar de 4', {
      '1': {
        name: 'Rengla 1',
        type: TRengla.RENGLA,
        pisos: {
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
        }
      },
      '2': {
        name: 'Rengla 2',
        type: TRengla.RENGLA,
        pisos: {
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
        }
      },
      '3': {
        name: 'Rengla 3',
        type: TRengla.RENGLA,
        pisos: {
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
        }
      },
      '4': {
        name: 'Rengla 4',
        type: TRengla.RENGLA,
        pisos: {
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
        }
      },
    }),
    '2': new Castell('2de8f', {
      '1': {
        name: 'Rengla 1',
        type: TRengla.RENGLA,
        pisos: {
          0: 'Pinya',
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
          5: 'Pinya',
          6: 'Pinya',
        }
      },
      '2': {
        name: 'Rengla 2',
        type: TRengla.RENGLA,
        pisos: {
          0: 'Pinya',
          1: 'Pinya',
          2: 'Pinya',
          3: 'Pinya',
          4: 'Pinya',
          5: 'Pinya',
          6: Tronc.Member.Zayra,
        }
      },
    }),
  }),
  2: new Day('Dimarts', '2024-10-25',
    {
      1: {
        name: '3de7a',
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marcos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Vito,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Xavi,
              3: Tronc.Member.Martina,
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Jorge,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Zayra,
            },
          },
          4: {
            name: 'Agulla',
            type: TRengla.AGULLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Rocio,
              3: 'Julia',
            },
          },
        },
      },
      2: {
        name: '4de7a',
        rengles: {
          1: {
            name: 'Dos',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marcos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Acotxador',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Jorge,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Gisela,
              3: Tronc.Member.Abril,
            },
          },
          3: {
            name: 'Dos',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Vito,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Xavi,
              3: Tronc.Member.NuriaB,
            },
          },
          4: {
            name: 'Enxaneta',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Albert,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Martina,
            },
          },
          5: {
            name: 'Agulla',
            type: TRengla.AGULLA,
            pisos: {
              0: Tronc.Member.MeriG,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Rocio,
              3: 'Julia',
            },
          },
        },
      },
      3: {
        name: '2de7',
        rengles: {
          1: {
            name: 'Acotxador',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
            },
          },
          2: {
            name: 'Enxaneta',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Silvia,
            },
          },
        },
      },
      4: {
        name: '3de8',
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Gisela,
              4: Tronc.Member.Silvia,
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
              4: Tronc.Member.Martina,
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Rucen,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Xavi,
              4: Tronc.Member.NuriaB,
            },
          },
        }
      },
      5: {
        name: '3de9f',
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Mutri,
              3: Tronc.Member.Gisela,
              4: Tronc.Member.Silvia,
              5: 'X',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Didac,
              2: Tronc.Member.Javi,
              3: Tronc.Member.Abril,
              4: Tronc.Member.Martina,
              5: 'Y',
            },
          },
          3: {
            name: 'Buida',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Rucen,
              1: Tronc.Member.Asier,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Xavi,
              4: Tronc.Member.NuriaB,
              5: 'Z',
            },
          },
        }
      },
      6: {
        name: 'Pde7f',
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Didac,
              3: Tronc.Member.Javi,
              4: Tronc.Member.Abril,
              5: 'Julia',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Marius,
            },
          },
        }
      },
      7: {
        name: 'Pde8fm',
        rengles: {
          1: {
            name: 'Rengla',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.VictorC,
              1: Tronc.Member.Avalos,
              2: Tronc.Member.Marcos,
              3: Tronc.Member.Didac,
              4: Tronc.Member.Javi,
              5: Tronc.Member.Irene,
              6: 'Julia',
            },
          },
          2: {
            name: 'Plena',
            type: TRengla.RENGLA,
            pisos: {
              0: Tronc.Member.Ruben,
              1: Tronc.Member.Marius,
              2: Tronc.Member.Xavi,
            },
          },
        }
      }
    }
  ),
};

export default events;